import styled, { css } from "styled-components";

const buttonStyle = css`
  background: none;
  outline: none;
  border: none;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  color: #ddd;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover {
    color: #fff;
    transform: scale(1.05);
  }
`;

export const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  //padding: 15px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: black;
  height: 50px;
  z-index: 2;
  > div:first-child {
    display: flex;
    align-items: center;
    > button {
      ${buttonStyle};
      padding-left: 15px;
      font-size: 25px;
      color: #d41763;
    }
  }
`;
export const HamburgerWrapper = styled.div`
  position: fixed;
  top: 15px;
  right: 15px;
  cursor: pointer;
  transition: all 0.5s ease-out;
  outline: none;
  z-index: 4;
  &.close {
    transform: rotate(180deg);
    > #btnLine {
      &:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -6px);
      }
    }
  }
  @media (min-width: 480px) {
    display: none;
  }
`;
export const MenuContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 50px;
  height: 0;
  z-index: 3;
`;
export const MenuLine = styled.div`
  width: 30px;
  height: 3px;
  margin: 0 0 5px 0;
  background: #fff;
  transition: all 0.5s ease-out;
`;
export const NavLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  color: #eee;
  padding-right: 15px;
  > button {
    ${buttonStyle};
  }
  > button:not(:last-child) {
    margin-right: 15px;
  }
  @media (max-width: 481px) {
    display: none;
  }
`;
